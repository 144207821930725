// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
#kt_body {
    font-size: 16px;
}

.kt-header__brand {
    align-items: center;
}

.MuiTablePagination-select {
    font-size: 1rem !important;
    line-height: 1rem !important;
    height: auto !important;
}

tr.MuiTableRow-root.MuiTableRow-head span {
    font-weight: 600 !important;
    font-size: 1.1rem !important;
}

tr.MuiTableRow-root:nth-of-type(odd) {
    background: #e9f3ff54 !important;
}

.MuiTableCell-root {
    border: 0 !important;
    font-size: 1rem !important;
}

tr.MuiTableRow-root.MuiTableRow-head {
    background: #e9f3ff !important;
}

tr.MuiTableRow-root:hover {
    background: #e9f3ff !important;
}

.withBorderRight {
    border-right: 2px solid #d3e3f2 !important;
}

img.label-icon {
    width: 17px;
    height: 17px;
    margin-left: 3px;
}

// Autocomplete select dropdown
.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding: 0;
}

.kt-menu__nav>li.kt-menu__item.kt-menu__item--here>a,
.kt-menu__subnav>li.kt-menu__item.kt-menu__item--active.kt-menu__item--here>a {
    background: #d3e3f2 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    // background: white;
    padding: 0 10px;
    margin-left: -7px;
}

.MuiSelect-selectMenu,
.MuiOutlinedInput-input {
    font-size: 16px !important;
    line-height: 16px !important;
    padding: 10px 15px !important;
    display: flex !important;
    align-items: center;
}

.MuiMenuItem-root {
    font-size: 16px;
    line-height: 16px;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
}

.MuiInputLabel-outlined {
    font-size: 16px !important;
    transform: translate(15px, 15px) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.centered-form {
    width: 100%;
    max-width: 640px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.action-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    button {
        margin: 0 5px;
        min-width: 110px !important;
    }
    .button-text {
        margin: 0 7px;
    }
}

textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
    padding: 0 !important;
}

.MuiFab-root {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08) !important;
}

// Backdrop blur on material dialogs
.MuiDialog-container.MuiDialog-scrollPaper {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: rgba(0, 0, 0, 0.4);
}

.icon-button {
    background: #ebf4ff;
    padding: 5px;
    border-radius: 40px;
    margin: 0 5px;
    cursor: pointer;
    color: #00507c;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-button svg {
    width: 20px;
    height: 20px;
}

.margin24 {
    margin: 24px;
}

.margin24h {
    margin-left: 24px;
    margin-right: 24px;
}

.flex-1 {
    flex: 1;
}

.MuiTablePagination-caption {
    font-size: 14px !important;
}

a.kt-subheader__breadcrumbs-home {
    padding: 0 !important;
}

.breadcrumbs-separator {
    margin: 0 5px;
}

.kt-header-menu-wrapper-overlay {
    backdrop-filter: blur(5px);
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
    font-size: 12px !important;
    background: #d0e2f7 !important;
    padding: 10px 18px !important;
    color: #00314c !important;
    border-radius: 100px !important;
    font-weight: 600 !important;
}

.kt-subheader {
    .btn.subheader-button {
        border-radius: 100px;
        padding: 15px 25px;
        height: 45px !important;
        font-size: 15px;
    }
    .btn.subheader-icon-button {
        padding: 0;
        width: 48px;
        height: 48px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-radius: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
        box-sizing: border-box;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
        display: flex;
        justify-content: center;
    }
}

.lightblue-button {
    background: #d3e3f2 !important;
    color: #00507c !important;
}

.fadedCell {
    opacity: 0.2;
}

td:last-of-type {
    padding: 5px;
}

.editTableCell .MuiFormControl-root.MuiTextField-root {
    display: flex;
    flex: 1;
}

.formSectionTitle {
    font-size: 19px;
    border-bottom: 1px solid;
    padding: 5px 0;
    margin: 20px 0 15px 0;
}

.MuiInputBase-input.Mui-disabled {
    color: black;
    cursor: not-allowed;
    background: #efefef;
}

.subheader-filter {
    background: #d3e3f2;
    border: 0 !important;
    border-radius: 100px;
    min-width: 150px;
    &:before,
    &.MuiInput-underline.Mui-focused:after,
    &.MuiInput-underline:after {
        display: none !important;
    }
    svg.MuiSvgIcon-root.MuiSelect-icon {
        margin-right: 10px;
        margin-top: 2px;
    }
}

.sidebar-filter {
    width: 100%;
}

.MuiTableRow-footer * {
    font-weight: 800 !important;
}

tfoot.MuiTableFooter-root {
    border-top: 2px solid #d3e3f2 !important;
}

.Mui-disabled {
    cursor: not-allowed !important;
}

.MuiOutlinedInput-notchedOutline legend {
    font-size: 12px !important;
}

.clear-date-button {
    background: #b7cce0;
    border-radius: 100px;
    margin-right: 10px;
    color: #00507c;
}

.PrivateNotchedOutline-legend-97 {
    width: auto !important;
    font-size: 12px;
    color: transparent;
    padding: 0 5px;
}

.disabled-input {
    background: #efefef;
}

// Checkbox
.MuiCheckbox-root {
    .MuiSvgIcon-root {
        width: 1.2em;
        height: 1.2em;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #2e5484 !important;
    }
}

.date-range-picker {
    background: #d3e3f2;
    border-radius: 100px;
    max-height: 50px;
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        border-radius: 100px;
    }
    p.MuiTypography-root.MuiPickersDateRangePickerInput-toLabelDelimiter.MuiTypography-body1 {
        display: none;
    }
    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
    .MuiFormControl-root.MuiTextField-root {
        width: 120px !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        display: none;
    }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation1.MuiPaper-rounded {
    z-index: 101 !important;
}

.MuiTooltip-popper {
    z-index: 100 !important;
}

// MOBILE MENU - START
.kt-header-menu-wrapper--on button#kt_header_menu_mobile_close_btn {
    border-radius: 30px;
    padding: 20px;
    right: 5px;
    top: 5px;
    left: auto;
    background: #00507c;
}

button#kt_header_menu_mobile_close_btn i {
    color: white;
    font-weight: 800;
}

.kt-header-menu-mobile .kt-menu__nav {
    padding-top: 0;
}

.kt-header-menu-mobile .kt-menu__nav * {
    font-size: 15px !important;
}

// MOBILE MENU - END
// dialogs
.MuiPaper-root.MuiDialog-paper {
    border-radius: 10px;
    min-width: 380px;
    .MuiDialogTitle-root.dialogTitle * {
        font-size: 20px;
        text-align: center;
    }
    .dialogDescription {
        font-size: 16px;
        text-align: center;
    }
    .dialogLottieAnimation {
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }
    .dialogDescription {
        font-size: 17px;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        display: flex;
        justify-content: center;
    }
    button.dialogButton {
        font-size: 14px;
        font-weight: 400;
        border-radius: 100px;
        padding: 10px 15px;
        min-width: 100px;
        margin-bottom: 15px;
    }
}

// dialogs - end
// BUTTONS
.grayBackgroundButton {
    background: #e5eaef !important;
}

.blueBackgroundButton {
    background: #00507c !important;
    color: white !important;
    &:hover {
        background: #004063 !important;
    }
}

.MuiTableCell-stickyHeader {
    background: #ebf4ff !important;
}

.subheader-icon-button {
    height: 50px;
    width: 50px;
}

// BUTTONS - END
.dashboard {
    .record-title {
        font-size: 22px;
        font-weight: 600;
        color: #00507c;
    }
    .record-title-section {
        display: flex;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        color: #00507c;
    }
    .record-date {
        width: 100%;
        margin-top: 7px;
        font-size: 15px;
        color: #00507c;
    }
    .number-title {
        font-size: 18px;
        font-weight: 500;
        color: #00507c;
    }
    h3.kt-subheader__title {
        margin-left: 20px;
        font-size: 34px;
        color: #00507c;
    }
}

.svg-icon.svg-icon-primary svg g [fill] {
    transition: fill .3s ease;
    fill: #498ee4!important;
}

.svg-icon.svg-icon-3x svg {
    height: 3rem!important;
    width: 3rem!important;
}

// --------------------------------- IMAGE UPLOADER - START ----------------------------------
.image-buttons-container {
    background: #2e5484d9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    cursor: pointer;
}

.image-buttons-container:hover {
    opacity: 1;
}

.image-container {
    position: relative;
}

// --------------------------------- IMAGE UPLOADER - END ----------------------------------
@media (max-width: 1024px) {
    .kt-header-menu-wrapper.kt-header-menu-wrapper--on {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }
    .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-wrapper {
        align-items: center;
    }
}

@media (max-width: 768px) {
    .kt-subheader__wrapper.d-flex {
        flex-direction: column;
    }
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.subheader-filter {
        flex-direction: column;
        padding: 0 20px;
    }
    .MuiPickersDateRangePickerInput-rangeInputsContainer {
        flex-direction: row !important;
    }
    // .makeStyles-root-16,
    // .makeStyles-tableWrapper-19,
    // .makeStyles-root-1457,
    // .makeStyles-tableWrapper-1460,
    .MuiTablePagination-root.margin24h {
        margin: 0 !important;
    }
    body {
        background-position: -175px 0%;
    }
}

// .kt-header__brand-logo{
//     background: white;
//     padding: 10px 20px;
//     border-radius: 100px;
//     box-shadow: 0 0 9px rgba(0,0,0,0.1);
// }
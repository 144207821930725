.initialLettersContainer {
    background: #ebf4ff;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    text-align: center;
    color: #00507c;
}

.pictureNameColumn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.pictureNameColumn {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullName {
    margin: 10px;
    font-size: 29px;
}

img.memberCard {
    width: 100%;
}

.noMemberData {
	text-align: center;
}

.subscription-title {
    text-align: center;
    font-size: 25px;
    border-bottom: 2px dashed #d5e1f1;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.member-date {
    text-align: center;
    font-size: 17px;
}

.subscription-item {
    display: flex;
    justify-content: space-around;
    margin: 10px;
    border-bottom: 1px solid #e4e4e4
    ;
    padding: 10px;
}

.subscription-list {
    margin-top: 20px;
}

.subscription-item:last-of-type {
    border-bottom: 0;
}

.payment-status-false {
    color: #e40000;
}

.payment-status-true {
    color: #62b78a;
}

.regnum span {
    background: #d3e3f2;
    padding: 5px 15px;
    border-radius: 100px;
    letter-spacing: 2px;
    font-family: cursive;
}

.regnum {
    margin-bottom: 15px;
    margin-top: 5px;
}